const awsconfig = {
  Auth: {
    region: 'us-east-1',
    userPoolId: 'us-east-1_8P8uni3bI',
    userPoolWebClientId: '59ecei2c5kd8li8ebfjgbj59nm',
    // Any other required authentication properties
  },
  // Other AWS service configurations if applicable
};

export default awsconfig;
