import React from 'react';
import Amplify from '@aws-amplify/core';
import awsconfig from './src/services/awsConfig.js';
import {Auth} from "@aws-amplify/auth";


export const onClientEntry = () => {
  Amplify.configure(awsconfig);
  Auth.configure(awsconfig);
};
